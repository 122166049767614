import { FC, useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getLocale } from './actual-locale'

type BundleValue = string | number | Date | ChunksFormatter
type ChunksFormatter = (chunks: string) => JSX.Element
type Props = Record<string, BundleValue> & {
	children: string
}

type TranslateFn = (id?: string, values?: Record<string, BundleValue>) => string

export const useTranslate = (): TranslateFn => {
	const intl = useIntl()
	return useCallback(
		(id?: string, values?: Record<string, BundleValue>): string => {
			return intl.formatMessage({ id }, values)?.toString() || ''
		},
		[intl]
	)
}

export const Translate: FC<Props> = ({ children, ...rest }) => (
	<FormattedMessage id={children} values={{ em: Emphasize, ...rest }} />
)

export const Emphasize: ChunksFormatter = chunks => <em>{chunks}</em>
export const ButtonChunk: ChunksFormatter = chunks => (
	<button type="button" role="link">
		{chunks}
	</button>
)

export const translate = (
	key: string,
	messages: {
		[x: string]: Record<string, string>
	},
	locale?: string
): string => {
	if (locale) {
		return messages[locale.substring(0, 2) ?? 'en'][key]
	}

	const fromBrowser = getLocale()
	return messages[fromBrowser.substring(0, 2)][key]
}

export default Translate
